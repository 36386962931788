import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import FancyTitle from './fancy-title';
import { handleize } from '../helpers/handleize';

function Positions({ title = 'Wen suchen wir?', currentPositionId }) {
  const data = useStaticQuery(
    graphql`
      query {
        allPersonioPosition {
          nodes {
            positionId
            name
            department {
              name
            }
            jobDescriptions {
              name
              value
            }
            employmentType
            seniority
            schedule
            yearsOfExperience
          }
        }
      }
    `,
  );

  let positions = data.allPersonioPosition.nodes;

  if (currentPositionId) {
    positions = positions.filter((position) => position.positionId !== currentPositionId);
  }

  const TAGS = {
    permanent: 'Festanstellung',
    'full-time': 'Vollzeit',
  };

  return (
    <div className="flex flex-col justify-start mt-[100px] lg:mt-60">
      <FancyTitle text={title} className="lg:text-center" tag="h2" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
        {positions && positions.map((position) => (
          <Link
            to={`/jobs/${handleize(position.name.split('(')[0])}`}
            target="_blank"
            rel="noreferrer"
            className="flex relative py-9 lg:py-20 px-8 lg:px-16 bg-latori-green-dark text-white"
            key={position.title}
          >
            <div className="absolute right-0 lg:right-3 top-0 lg:top-3 w-full flex justify-end gap-3">
              {position.employmentType && (
              <div className="bg-green-900 p-2 text-xs lg:text-base">{TAGS[position.employmentType]}</div>
              )}
              {position.schedule && (
              <div className="bg-green-900 p-2 text-xs lg:text-base">{TAGS[position.schedule]}</div>
              )}
            </div>
            <div className="w-full flex justify-start items-center">
              {' '}
              <h3 className="text-2xl lg:text-3xl font-semibold">{position.name}</h3>
            </div>
          </Link>
        ))}
        {!positions.length && (
          <div className="flex col-span-full justify-center">
            <p className="lg:text-xl">Aktuell haben wir keine offenen Stellen.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Positions;
