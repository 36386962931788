import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import FancyTitle from '../components/fancy-title';
import Positions from '../components/positions';
import Perks from '../components/perks';
import Testimonials from '../components/testimonials';

function Jobs() {
  const slideShowWidth = 2400;

  const data = useStaticQuery(
    graphql`
      query {
        allJobsYaml {
          edges {
            node {
              description
              meta_description
              gallery1 {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1024
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              gallery2 {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1024
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              perks {
                icon {
                  iconName
                  prefix
                }
                title
              }
              positions {
                tags
                title
                link
              }
              testimonials {
                description
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 480
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                subtitle
                title
              }
              title
            }
          }
        }
      }
    `,
  );

  const jobs = data.allJobsYaml.edges[0].node;
  const { perks } = jobs;
  const gallery1 = jobs.gallery1 ? jobs.gallery1.map((item) => ({ node: item })) : null;
  const gallery2 = jobs.gallery2 ? jobs.gallery2.map((item) => ({ node: item })) : null;
  const testimonials = jobs.testimonials ? jobs.testimonials.map((item) => ({ node: item })) : null;
  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: 'Jobs', fullWidth: true });
        return (
          <>
            <Seo title={jobs.title} description={jobs.meta_description} />
            <div className="px-4 xl:px-16 flex flex-col pb-[100px] pt-20 xl:py-36 xl:pb-36">
              <div className="flex flex-col justify-start mb-8 xl:mb-24 w-full">
                <FancyTitle
                  text="Join us!"
                  className="text-left"
                  tag="h1"
                  marginBottom="mb-8 xl:mb-20"
                />
                <p className="xl:text-xl hidden xl:block" dangerouslySetInnerHTML={{ __html: jobs.description }} />
              </div>

              <div className="overflow-hidden slideshow -ml-4 w-[calc(100%+2rem)] xl:-ml-16 xl:w-[calc(100%+8rem)]" style={{ '--slideshow-width': `-${slideShowWidth + 12}px`, '--slideshow-duration': '40s' }}>
                <div className="slideshow-track flex flex-row gap-3">
                  {[1, 2].map((count) => (
                    <div key={`track-loop-${count}`}>
                      <div className="flex mb-3 gap-3" style={{ width: `${slideShowWidth}px` }}>
                        {gallery1.map((edge) => {
                          const reference = edge.node;
                          const image = getImage(reference.image);
                          return (
                            <div key={`gallery-1-loop-${reference.alt}`}>
                              {image && (
                              <div className="object-cover grow max-h-full">
                                <GatsbyImage
                                  image={image}
                                  imgStyle="bg-green-600"
                                  className="h-full"
                                  alt={reference.alt}
                                />
                              </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex ml-[-150px] gap-3 mr-[150px]" style={{ width: `${slideShowWidth}px` }}>
                        {gallery2.map((edge) => {
                          const reference = edge.node;
                          const image = getImage(reference.image);
                          return (
                            <div key={`gallery-2-loop-${reference.alt}`}>
                              {image && (
                              <div className="object-cover grow max-h-full">
                                <GatsbyImage
                                  image={image}
                                  imgStyle="bg-green-600"
                                  className="h-full"
                                  alt={reference.alt}
                                />
                              </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-col justify-start mb-8 xl:mb-24 w-full">
                <p className="xl:text-xl block xl:hidden mt-16" dangerouslySetInnerHTML={{ __html: jobs.description }} />
              </div>

              <Positions />
              <Perks data={perks} />
              <div className="mt-[52px] xl:mt-48">
                <Testimonials
                  data={testimonials}
                  title="Warum Latori?"
                  subtitle={null}
                  imageClasses="rounded-full"
                />
              </div>
            </div>
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default Jobs;
