import * as React from 'react';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FancyTitle from './fancy-title';
import * as icons from '../icons/icon-pack';

function Perks({ data }) {
  library.add({ ...icons });
  return (
    <div className="flex flex-col justify-start mt-[100px] lg:mt-60">
      <FancyTitle text="Was erwartet dich?" className="text-left my-10" tag="h2" />
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-4 gap-y-16">
        {data.map((perk) => (
          <div key={perk.title} className="m-1 flex flex-col">
            <div className="flex justify-center align-center text-center">
              <div className="rounded-full w-[60px] h-[60px] inline-flex justify-center items-center bg-latori-green-dark mb-5 text-white">
                <FontAwesomeIcon icon={icon(perk.icon)} size="2x" />
              </div>
            </div>
            <p className="text-xl font-semibold text-center mb-5 break-words" dangerouslySetInnerHTML={{ __html: perk.title }} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Perks;
